html, body {
  background: black;
  height: 100%;
  width: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
}

* {
  touch-action: manipulation;
  -moz-user-select: none; /* Firefox */ 
  -ms-user-select: none; /* Internet Explorer */ 
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */ 
  -webkit-user-select: none; /* Chrome, Safari, and Opera */ 
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/ 
} 
  

body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.phone {
  height: 50px;
  width: 100px;
  border: 3px solid white;
  border-radius: 10px;
  animation: rotate 1.5s ease-in-out infinite alternate;
  display: none;
}

.message {
  color: white;
  font-size: 1em;
  margin-top: 40px;
  display: none;
}

@keyframes rotate {
  0% {
		transform: rotate(0deg)
	}
	50% {
		transform: rotate(-90deg)
	}
	100% {
		transform: rotate(-90deg)
	}
}

@media only screen and (max-device-width: 812px) and (orientation: portrait) {
  .phone, .message {
    display: block;
  }

  .game {
    display: none;
  }
}