html, body {
  height: 100%;
  width: 100%;
  background: #000;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

* {
  touch-action: manipulation;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  -webkit-touch-callout: none;
}

body {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.phone {
  height: 50px;
  width: 100px;
  border: 3px solid #fff;
  border-radius: 10px;
  animation: 1.5s ease-in-out infinite alternate rotate;
  display: none;
}

.message {
  color: #fff;
  margin-top: 40px;
  font-size: 1em;
  display: none;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(-90deg);
  }

  100% {
    transform: rotate(-90deg);
  }
}

@media only screen and (max-device-width: 812px) and (orientation: portrait) {
  .phone, .message {
    display: block;
  }

  .game {
    display: none;
  }
}

/*# sourceMappingURL=index.8e3df373.css.map */
